.-hide {
    display: none!important;
}

.-hideOverflow {
    overflow: hidden;
}

.-static{
    position: static;
}

.-blur {
    filter: blur(7px);
}

.nopadding > [class*="col-"] {
     padding-left: 0 !important;
     padding-right: 0 !important;
}

.nopadding-xs > [class*="col-"] {
    @include mq(xs){
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.nopadding-selective > .nopad-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.-vertical-space{
    padding-top: $base;
    padding-bottom: $base;

    &-top {
        padding-top: $base;
    }

    &-bottom {
        padding-bottom: $base;
    }
}

.embed-responsive.-map{
    padding-bottom: 56.25%;

    @include mq(sm){
        padding-bottom: 45%;
    }

    @include mq(md){
        padding-bottom: 30%;
    }
}

[data-parallax]{
    position: relative;
}

.arrow--down {
  position: relative;
  height: 40px;
  width: 15px;
  display: inline-block;

  &:before {
    content: '';
    width: 1px;
    position: absolute;
    left: 50%;
    top:0;
    bottom:0;
    background: $white;
  }

  &:after {
    content: '';
    width: 8px;
    position: absolute;
    left: 0;
    bottom:0;
    left:0;
    height:8px;
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    transform: rotate(45deg);
    transform-origin: right bottom;
  }
}

.arrow--right {
  position: relative;
  width: 40px;
  height: 15px;
  display: inline-block;

  &:before {
    content: '';
    height: 1px;
    position: absolute;
    top: 53%;
    left:0;
    right:0;
    background: $mainColor;
  }

  &:after {
    content: '';
    height: 8px;
    position: absolute;
    top: 3px;
    right:3px;
    width:8px;
    border-bottom: 1px solid $mainColor;
    border-right: 1px solid $mainColor;
    transform: rotate(-45deg);
    transform-origin: right top;
  }
}
