.fincas {

  &__hero {
	
		&__content {
			position: absolute;
      z-index: 10;
      top:0;
      max-width: 1200px;
      bottom:0;
      display: grid;
      grid-template-columns: 1fr minmax(280px, 680px) 1fr;
      grid-template-rows: 1fr auto 1fr 1fr;
		}

		&__text {
      grid-column: 2;
      grid-row: 2;
      color: $white;
      text-align: center;
    }

    &__title {
      font-family: $titleFont;
      font-style: italic;
      font-weight: normal;
      margin: 0 0 20px;

      @include mq(md) {
        font-size: 48px;
      }
    }

    &__subtitle {
      line-height: 1.5;
      letter-spacing: 0.5px;
      margin: 0 0 50px;

      @include mq(md) {
        font-size: 18px;
      }
    }
		
		&__map {
			margin-bottom: 3rem;
			max-width: 90%;
		}

    .arrow--down {
      grid-column: 2;
      grid-row: 3;
      justify-self: center;

			@include mq(xs) {
				height: 30px;
				width: 15px;
			}
    }

		.image-box {
			&:after {
				@include mq(xs) {
					background: rgba(0,0,0,.4);
					right:0;
				}
			}
			@include mq(md) {
        padding-bottom: 47%;
      }
		}

	}


	&__section {
		background-color: $lightBeige;
		align-items: center;
		position: relative;

		@include mq(md) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

    &:before{
      content: '';
      height: 5px;
      background: $mainColor;
      width: 90%;
      max-width: 1200px;
      left: 0;
      right:0;
      margin: auto;
      top:0;
      z-index: 10;
      display: block;
      position: absolute;
    }


		&__content {
			grid-column: 1;
			max-width: 600px;
			justify-self: end;
			padding: 2rem;

      @include mq(md) {
        padding: 2rem 12rem 2rem 2rem;
      }
		}
		
		&__text {
      color: $gold;
      text-align: left;
		}

		&__title {
			font-family: $titleFont;
      font-style: italic;
      font-weight: normal;
      margin: 0 0 20px;

			@include mq(md) {
        font-size: 1.5rem;
      }
		}

		&__subtitle {
			line-height: 2;
			color: $altGray;

			@include mq(md) {
        font-size: 16px;
      }
		}

		&__letter {
			line-height: 2;
			color: $altGray;
			text-transform: uppercase;

			@include mq(md) {
        font-size: 14px;
      }
		}

		&__map {
			grid-column: 2;
			width: 100%;
			height: 100%;
			position: relative;

			ul {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}

			li {
				.mapa__link {
					display: flex;
      		align-items: center;
      		justify-content: center;
      		position: absolute;
      		width: 40px;
      		height: 40px;

      		._circle {
      		  border: 1px solid $white;
      		  width: 100%;
      		  height: 100%;
      		  border-radius: 50%;
      		  position: absolute;
      		}
				
      		._cross {
      		  &:before,
      		  &:after {
      		    content: '';
      		    width: 16px;
      		    height: 1px;
      		    background: $white;
      		    display: block;
      		    position: relative;
      		  }
					
      		  &:after {
      		    transform: rotate(90deg);
      		  }
      		}
				}

				&:hover {
					& > img {
						display: block;
					}
				}

				&[data-finca='jamilla'] {
					.mapa__link {
						left: 52%;
						top: 25%;
					}
				}

				&[data-finca='lauren'] {
					.mapa__link {
						left: 46%;
						top: 78%;
					}
				}

				&[data-finca='naoki'] {
					.mapa__link {
						left: 54%;
						top: 74%;
					}
				}

				&[data-finca='owen'] {
					.mapa__link {
						left: 30%;
						top: 79%;
					}
				}

				img {
					max-width: 100%;
					display: none;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate3d(-50%, -50%, 0);
				}
			}

			& > img {
				width: 100%;
				display: block;
			}
		}

	}

	&__explore {

		&__content {
			position: absolute;
      z-index: 10;
      top:0;
			left: 0;
			right: 0;
      bottom:0;
			padding: 2rem 2rem 0rem 2rem;
			margin: auto;
			
			@include mq(md) {
				max-width: 1200px;
				display: grid;
				grid-template-columns: 1fr minmax(280px, 900px) 1fr;
				grid-template-rows: 1fr auto 1fr 1fr;
			}
		}

		&__text {
      grid-column: 2;
      grid-row: 2;
      color: $white;
      text-align: center;
			max-width: 680px;
			margin-bottom: 2rem;
			justify-self: center;
			
			@include mq(md) {
				margin-bottom: 7rem;
			}
    }

    &__title {
      font-family: $titleFont;
      font-weight: normal;
      margin: 0 0 20px;
			
			@include mq(md) {
				font-size: 48px;	
			}
    }

    &__subtitle {
			line-height: 1.5;
      letter-spacing: 0.5px;
			
			@include mq(md) {
				font-size: 18px;
			}
    }

		.icons__container {
			display: flex;
			gap: 2rem;
      grid-column: 2;
      grid-row: 3;
      justify-self: center;
			flex-wrap: wrap;
			justify-content: center;

			@include mq(md) {
				gap: 9rem;
			}
    }

		.icons__box {
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				color: $gold;
				text-transform: uppercase;
				text-align: center;
				line-height: 1.1rem;
			}

			img {
				width: fit-content;
			}
		}

		.image-box {
			@include mq(xs) {
				padding-bottom: 150%;
			}
		}

		.image-box:after {
			background: rgba(0, 0, 0, .38);
		}
	}
	
	&__images {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__desc {
		background-color: $lightBeige;
		display: flex;
		justify-content: center;

		@include mq(xs) {
			padding: 1rem;
		}

		&__content {
			max-width: 1200px;
			
			@include mq(md) {
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-template-rows: 4rem 1fr 4rem;
			}
		}

		&__title {
			font-family: $titleFont;
			font-weight: normal;
			grid-column: 1;
			grid-row: 2;
			color: $gold;
			text-align: center;

			@include mq(xs) {
				padding-right: 1rem;
			}
		}

		&__subtitle {
			grid-column: 2;
			grid-row: 2;
			color: $altGray;
      line-height: 1.5;
      letter-spacing: 0.5px;
			
			@include mq(md) {
				border-left: $gold 2px solid;
				padding-left: 2rem;
			}
		}
	}
}