.vino {
  &__otros {
    background: $beige;
    position: relative;
    padding: 5% 0;

    &:before {
      content: '';
      height: 5px;
      background: $mainColor;
      width: 90%;
      max-width: 1200px;
      left: 0;
      right:0;
      margin: auto;
      top:0;
      z-index: 10;
      display: block;
      position: absolute;
    }

    &__title {
      font-family: $titleFont;
      text-transform: uppercase;
      font-weight: normal;
      color: $altGray;
    }

    &__list {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      padding-top: 15px;
      padding-bottom: 15px;

      @include mq(md) {
        gap: 30px;
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &__name {
      font-family: $titleFont;
      font-weight: normal;
      color: $altGray;
      font-size: 16px;
      margin: 0;
      text-align: center;
    }

    &__img {
      margin-bottom: 10px;
      height: 120px;

      @include mq(md) {
        height: 150px;
      }
    }

    &__item {
      a {
        display: flex;
        padding: 15px;
        text-decoration: none;
        flex-direction: column;
        align-items: center;
      }

      &:hover {
        background: rgba(0,0,0,.1);
      }
    }
  }

  &__container {
    max-width: 1200px;
    width: 90%;
    margin: auto;

    &--grid {
      grid-template-columns: 0.33fr 0.67fr;
      display: grid;
      gap: 15px;

      @include mq(md) {
        grid-template-columns: 0.33fr 0.587fr 0.083fr;
      }
    }
  }

  &__chart {
    &__heading {
      background: #e2dfd6;
    }

    &__bottle {
      justify-self: center;
      align-self: start;

      @include mq(md) {
        align-self: end;
        grid-row: 1 / span 2;
      }

      img {
        display: block;
        width: 100px;
        
        @include mq(md) {
          margin-top: -60%;
          max-width: 230px;
          width: auto;
        }
      }
    }

    &__content {
      padding: 7% 0;
      display: none;

      &.js-active {
        display: block;
      }
    }

    &__data {
      grid-column: 2;
    }

    &__ficha {
      align-self: end;
      grid-column: 1 / span 2;
      
      @include mq(md) {
        grid-column: 2;
      }
    }

    &__ficha-tabs {
      gap: 50px;
      justify-content: center;
      border-top: 1px solid $lightGray;
      border-bottom: 1px solid $lightGray;
      
      @include mq(md) {
        display: flex;
      }

      li {
        padding: 20px 0;
        position: relative;
        color: lighten($altGray, 30%);
        text-transform: uppercase;
        font-family: $titleFont;
        font-size: 18px;
  
        &:hover {
          color: $mainColor;
          cursor: pointer;
        }
    
        &.js-active {
          pointer-events: none;
          color: $mainColor;
    
          &:before {
            content: '';
            width: 15px;
            height: 15px;
            background: $white;
            transform: rotate(45deg);
            position: absolute;
            left:-25px;
            bottom: 22px;
            border-top: 1px solid $lightGray;
            border-right: 1px solid $lightGray;

            @include mq(md) {
              margin: auto;
              left:0;
              right:0;
              bottom:-8px;
              transform: rotate(-45deg);
            }
          }
    
          &:hover {
            cursor: default;
          }
        }
      }
    }

    &__ficha-content {
      padding-top: 50px;
      padding-bottom: 50px;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      display: none;

      &.js-active {
        display: grid;
      }

      @include mq(md) {
        gap: 30px;
      }

      ._varietal,
      ._bottle,
      ._cup {
        display: grid;
        gap: 10px;
        grid-template-columns: 30px 1fr;

        @include mq(md) {
          grid-template-columns: 50px 1fr;
        }

        ._label {
          text-transform: uppercase;
          color: lighten($altGray, 30%);
          display: block;
          grid-column: 2;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.2px;
        }

        ._desc {
          grid-column: 2;
          color: $altGray;
          font-size: 16px;
          font-weight: 600;
          margin: 0;

          @include mq(md) {
            font-size: 20px;
          }
        }

        ._icon {
          grid-row: 1 / span 2;
          justify-self: center;
          height: 32px;

          @include mq(md) {
            height: 45px;
          }
        }
      }
      

      ._cup {
        grid-column: 1 / 3;
        padding-top: 15px;

        ._desc {
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }

    &__name {
      color: $mainColor;
      margin: 0 0 20px;
      font-weight: normal;
      font-size: 28px;
      font-family: $titleFont;

      @include mq(md) {
        font-size: 36px;
      }
    }

    &__desc {
      color: $altGray;
      line-height: 2;
      font-size: 16px;
    }
  }

  &__tabs {
    grid-column: 1 / 3;

    @include mq(md) {
      grid-column: 2;
      display: flex;
      gap: 50px;
    }
  }

  &__tab {
    padding: 15px 0;
    position: relative;
    color: lighten($altGray, 30%);
    text-transform: uppercase;
    font-family: $titleFont;
    font-size: 18px;

    &:hover {
      color: $mainColor;
      cursor: pointer;
    }

    &.js-active {
      pointer-events: none;
      color: $mainColor;

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        background: $white;
        transform: rotate(-45deg);
        position: absolute;
        left:-28px;
        margin: auto;
        bottom:18px;

        @include mq(md) {
          left:0;
          right:0;
          margin: auto;
          bottom:-10px;
        }
      }

      &:hover {
        cursor: default;
      }
    }

    @include mq(md) {
      padding: 30px 0; 
    }
  }
}