.c-button {
  $b: &;

  background-color: transparent;
  text-transform: uppercase;
  font-size: .8rem;
  border: $mainColor solid 1px;
  color: $mainColor;
  padding: .8rem 1.9rem;
  letter-spacing: 0.15rem;
  text-decoration: none;
  font-family: $mainFont;

  &--filled {
    background-color: $mainColor;
    color: $white;
  }

  &--white {
    border: $white solid 1px;
    color: $white;

    &#{$b}__filled {
      background-color: $white;
      color: $mainColor;
    }
  }

  &--gray {
    border: $altGray solid 1px;
    color: $altGray;

    &#{$b}__filled {
      background-color: $altGray;
    }
  }

  &--big {
    font-size: .9rem;
    padding: 1.1rem 3.5rem;  
  }
}