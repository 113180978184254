.vinos {
  &__hero {

    &__content {
      position: absolute;
      z-index: 10;
      top:0;
      width:100%;
      bottom:0;
      display: grid;
      grid-template-columns: 1fr minmax(280px, 680px) 1fr;
      grid-template-rows: 1fr auto 1fr;
      padding-right: 15px;
      padding-left: 15px;
    }
  
    &__text {
      grid-column: 2;
      grid-row: 2;
      color: $white;
      text-align: center;
    }
  
    &__title {
      font-family: $titleFont;
      font-size: 42px;
      font-style: italic;
      font-weight: normal;
      margin: 0 0 20px;
  
      @include mq(md) {
        font-size: 64px;
      }
    }
  
    &__subtitle {
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      margin: 0 0 50px;
  
      @include mq(md) {
        font-size: 24px;
      }
    }
  
    .arrow--down {
      grid-column: 2;
      grid-row: 3;
      justify-self: center;
    }
  }

  &__list {
    display: grid;

    @include mq(md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__vino {
    $v: &;

    position: relative;
    height: 0;
    padding-bottom: 80%;
    overflow: hidden;

    @include mq(md) {
      padding-bottom: 65%;
    }

    &:hover {
      & > #{$v}__data {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &__img {
      position: absolute;
      top:0;
      left:0;
      height: 100%;

      @include mq(md) {
        height: auto;
        width: 100%;
      }
    } 

    &__data {
      @include trans;
      position: absolute;
      top: 0;
      left:0;
      right:0;
      bottom:0;
      opacity: 0;
      background: rgba(0,0,0,.6);
      transform: translateY(100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      &::before {
        content: '';
        position: absolute;
        z-index: 2;
        width: 60%;
        height: 5px;
        margin: auto;
        left:0;
        right: 0;
        top: 0;
        background: $mainColor;
      }
    }

    &__name {
      color: $mainColor;
      font-size: 36px;
      font-family: $titleFont;
      font-weight: normal;
      margin: 0 0 10px;

      @include mq(md) {
        font-size: 42px;
        margin: 0 0 20px;
      }
    }

    &__desc {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      max-width: 400px;
      margin: 0 0 15px;
      
      @include mq(md) {
        font-size: 18px;
        line-height: 2;
        margin: 0 0 20px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 40px;
      height: 40px;

      ._circle {
        border: 1px solid $white;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
      }

      ._cross {
        &:before,
        &:after {
          content: '';
          width: 16px;
          height: 1px;
          background: $white;
          display: block;
          position: relative;
        }

        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
}