.nosotros {
  background-color: black;
	&__hero {

    &__content {
      position: absolute;
      z-index: 10;
      top:0;
      max-width: 1200px;
      bottom:0;
      display: grid;
      grid-template-columns: 1fr minmax(280px, 500px) 1fr;
      grid-template-rows: 1fr auto 1fr;
    }

    &__text {
      grid-column: 2;
      grid-row: 2;
      color: $white;
      text-align: center;
    }

    &__title {
      font-family: $titleFont;
      font-size: 36px;
      font-style: italic;
      font-weight: normal;
      margin: 0 0 20px;

      @include mq(md) {
        font-size: 56px;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      margin: 0 0 50px;

      @include mq(md) {
        font-size: 24px;
      }
    }

    .arrow--down {
      grid-column: 2;
      grid-row: 3;
      justify-self: center;
    }
  }
	

  &__section {
    background-color: $beige;
    height: 10rem;

    &-content {
      max-width: 1200px;
      margin: auto;
      border-top: $gold solid 5px;
      height: 10rem;
      display: grid;
      justify-items: center;
      padding-left: 15px;
      padding-right: 15px;
      
      @include mq(md) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }

      &--container {
        max-width: 25rem;
        text-align: center;
        grid-column: 2;
        grid-row: 2;
        h3 {
          color: $gold;
          font-size: 1.5rem;
          font-style: italic;
          font-weight: 100;
          font-family: $titleFont;
          text-align: center;
        }
      }
    }
  }

  &__explore {

    .image-box {
      &:after {
        @include mq(xs) {
          background: rgba(0,0,0,.4);
          right:0;
        }
      }
    }

    &--bot {
      position: relative;

      &:before{
        content: '';
        height: 5px;
        background: $mainColor;
        width: 90%;
        max-width: 1200px;
        left: 0;
        right:0;
        margin: auto;
        top:0;
        z-index: 10;
        display: block;
        position: absolute;
      }
    }
	
		&__content {
      position: absolute;
      z-index: 10;
      top:0;
      max-width: 1200px;
      bottom:0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr auto 1fr;
      padding-left: 15px;
      padding-right: 15px;
		}

    &__text {
      grid-column: 1;
      grid-row: 2;
      color: $white;
      text-align: left;
      max-width: 500px;
    }

		&__title {
      font-family: $titleFont;
      font-size: 36px;
      font-weight: normal;
      margin: 0 0 20px;

      @include mq(md) {
        font-size: 56px;
      }
		}

		&__subtitle {
			font-size: 18px;
      line-height: 2;
      letter-spacing: 0.5px;
      margin: 0 0 80px;

      @include mq(md) {
        font-size: 16px;
      }
		}

		.up {
			margin: 0 0 20px;
      font-weight: 400;
		}

		.down {
			margin: 0 0 50px;
      font-style: italic;
		}
  }

  &__grid {
    background-color: $beige;
    position: relative;

    &:before {
      content: '';
      height: 5px;
      background: $mainColor;
      width: 90%;
      max-width: 1200px;
      left: 0;
      right:0;
      margin: auto;
      top:0;
      z-index: 10;
      display: block;
      position: absolute;
    }
      
    &-content {
      padding: 8% 0rem;
      display: grid;
      gap: 2rem;
      justify-items: center;
      
      @include mq(md) {        
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 0.45fr 0.30fr 0.10fr 0.15fr;
      }

      &--el1 {
        align-self: center;
        width: 60%;
        
        @include mq(md) {
          grid-column: 1;
          grid-row: 1 / 2;
        }
        
        p {
            font-size: 16px;
            line-height: 2;
            color: $altGray;
            margin: 0 0 10%;
        }
      }
      
      &--el2 {
        width: 100%;
        height: 100%;
        
        @include mq(md) {
          grid-column: 1;
          grid-row: 2 / 5;
        }
      }
      
      &--el3 {
        width: 100%;
        height: 100%;
        
        @include mq(md) {
          grid-column: 2;
          grid-row: 1 / 3;
        }
      }
      
      &--el4 {
        width: 100%;
        height: 100%;
        justify-self: start;
        
        @include mq(md) {
          width: 80%;
          grid-column: 2;
          grid-row: 3;
        }
      }

      &--el5 {
        justify-self: start;
        align-self: end;
        margin-left: 10%;
        width: 70%;
        
        @include mq(md) {
          grid-column: 2;
          grid-row: 4;
        }

        p {
          font-size: 16px;
          line-height: 2;
          color: $altGray;
          margin: 10% 0 0;
        }
      }
    }
  }
}
