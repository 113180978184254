.header {

    $h: &;

    position: fixed;
    background: $corpBlack;
    display: grid;
    gap: 5px;
    padding: 10px 15px;
    grid-template-columns: auto 1fr 30px;
    top: 0;
    width:100%;
    left:0;
    z-index: 10000;

    @include mq(md) {
      grid-template-columns: 1fr minmax(900px, 1200px) 1fr;
      padding: 0;
    }

    &__brand {
      @include mq(md) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 10;
      }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        grid-column: 3;
        justify-self: end;
        align-self: center;

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md) {
          display: none;
        }
    }

    &__logo {
      height: 40px;
      display: block;

      @include mq(md) {
        height: 55px;
      }

      img {
        height: 100%;
      }
    }

    &__nav {
      position: fixed;
      top: 60px;
      bottom: 0;
      left:0;
      right: 0;
      background: $corpBlack;
      padding: 10px 15px;
      display: grid;
      transform: translateX(-100%);
      @include trans;

      @include mq(md) {
        position: static;
        transform: translateX(0);
        grid-column: 2;
        padding: 40px 0;
        background: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: 15px;
        right: 15px;
        height: 3px;
        background: $mainColor;
        top: 0;
        z-index: 10;

        @include mq(md) {
          height: 5px;
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
        }
      }
    }

    &__menu {
      align-self: center;
      justify-self: center;
      
      @include mq(md) {
        width: 100%;
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(4, auto) 220px repeat(3, auto);
      }

      li{

        &:nth-child(4) {
          @include mq(md) {
            grid-column: 6;
            justify-self: end;
          }
        }

        &:nth-child(5) {
          @include mq(md) {
            grid-column: 7;
            justify-self: end;
          }
        }

        &:nth-child(6) {
          @include mq(md) {
            grid-column: 8;
            justify-self: end;
          }
        }

        &:not(:last-of-type) {
          margin-bottom: 15px;

          @include mq(md) {
            margin-bottom: 0;
          }  
        }
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: $altGray;

        @include mq(md) {
          font-size: 14px;
        } 
      }
    }

    &__language {
      grid-column: 2;
      justify-self: end;
      align-self: center;
      background: none;
      border:none;
      color: $altGray;
      font-size: 14px;
      font-weight: 400;

      @include mq(md) {
        grid-column: 3;
        justify-self: center;
      }  
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
          transform: translateX(0);
        }
    }

    & + main {
      padding-top: 60px;

      @include mq(md) {
        padding-top: 98px;
      }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
