.image-box {
  width: 100%;
  position: relative;
  padding-bottom: 150%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  @include mq(md) {
    padding-bottom: 43%;
  }
  
  &__image {
    position: absolute;
    top:50%;
    left: 0;
    height:100%;
    transform: translateY(-50%);

    @include mq(md) {
      height: auto;
      width:100%;
    }
  }

  
  &--grad-bottom {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left:0;
      right:0;
      top: 40%;
      background: linear-gradient(to top, rgba(0,0,0,0.9), transparent);
    }
  }

  &--grad-left {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left:0;
      right:40%;
      top: 0;
      background: linear-gradient(to right, rgba(0,0,0,0.5), transparent);
    }
  }

  &--full {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left:0;
      right:0;
      top: 0;
      background: rgba(0,0,0,0.2);
    }
  }

  &--small {
    padding-bottom: 120%;

    @include mq(md) {
      padding-bottom: 39%;
    }
  }
}