@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


@font-face {
  font-family: 'Droid Serif';
  src: url('/fonts/DroidSerif-Regular-webfont.eot');
  src: url('/fonts/DroidSerif-Regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/DroidSerif-Regular-webfont.woff2') format('woff2'),
       url('/fonts/DroidSerif-Regular-webfont.woff') format('woff'),
       url('/fonts/DroidSerif-Regular-webfont.ttf') format('truetype'),
       url('/fonts/DroidSerif-Regular-webfont.svg#droid_serifregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('/fonts/DroidSerif-Italic-webfont.eot');
  src: url('/fonts/DroidSerif-Italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/DroidSerif-Italic-webfont.woff2') format('woff2'),
       url('/fonts/DroidSerif-Italic-webfont.woff') format('woff'),
       url('/fonts/DroidSerif-Italic-webfont.ttf') format('truetype'),
       url('/fonts/DroidSerif-Italic-webfont.svg#droid_serifregular') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('/fonts/DroidSerif-Bold-webfont.eot');
  src: url('/fonts/DroidSerif-Bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/DroidSerif-Bold-webfont.woff2') format('woff2'),
       url('/fonts/DroidSerif-Bold-webfont.woff') format('woff'),
       url('/fonts/DroidSerif-Bold-webfont.ttf') format('truetype'),
       url('/fonts/DroidSerif-Bold-webfont.svg#droid_serifregular') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('/fonts/DroidSerif-BoldItalic-webfont.eot');
  src: url('/fonts/DroidSerif-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/DroidSerif-BoldItalic-webfont.woff2') format('woff2'),
       url('/fonts/DroidSerif-BoldItalic-webfont.woff') format('woff'),
       url('/fonts/DroidSerif-BoldItalic-webfont.ttf') format('truetype'),
       url('/fonts/DroidSerif-BoldItalic-webfont.svg#droid_serifregular') format('svg');
  font-weight: 600;
  font-style: italic;
}
